@use "../../shared";

#main_content {
    text-align: center;
}

#loading {
    margin-bottom: 50px;
    font-size: 40px;
}

#error {
    color: #FF0000;
    font-size: 20px;
    margin-bottom: 20px;
}

#title {
    margin-bottom: 25px;
    font-size: 60px;
}

#subtitle {
    font-size: 30px;
}

#profile_photo {
    margin-bottom: 25px;
    height: 150px;
    border-radius: 8px;
    @include shared.responsive(){
        height: 100px;
        border-radius: 4px;
    }
}

#status {
    margin-top: 25px;
    font-size: 25px;
    font-weight: normal;
}