@use "../../shared";

#navbar {
    height: 10vh;
    max-width: 100vw;
    box-sizing: border-box;
    position: relative;
    padding: 5px 20vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    a {
        text-align: center;
    }
    @include shared.responsive(){
        padding: 5px 1vw;
    }
}
#flex_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    @include shared.responsive(){
        gap: 15px;
    }
}
#flex_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    @include shared.responsive(){
        gap: 15px;
    }
}
#name {
    font-size: 2vh;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0px 0px 8px #00000055;
}
.navbar_link {
    &:hover {
        color: shared.$gradient-to;
    }
}
#gradient_effect {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    background-image: linear-gradient(to right, shared.$gradient-from, shared.$gradient-to);
}