@use "./shared";
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

body {
    font-family: "Lexend", "Arial", sans-serif;
    margin: 0;
}

html {
    overflow-x: hidden;
}

p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

a.nostyle {
    text-decoration: none;
    color: inherit;
}

.gradient_text {
    background: linear-gradient(to bottom right, shared.$gradient-from, shared.$gradient-to);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rails {
    padding: 10vh 20vw;
    max-width: 100vw;
    @include shared.responsive(){
        padding: 10vh 2vw;
    }
}
