@use "../../shared";

#main_content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    & > div {
        padding: 15px;
    }
    p {
        font-size: 25px;
        margin-bottom: 20px;
    }
    h1 {
        font-size: 40px;
        margin-bottom: 20px;
    }
}

#options_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

#error {
    color: #FF0000;
    font-size: 20px;
    margin-bottom: 20px;
}

.option {
    background: none;
    border: 2px solid #AAAAAA;
    border-radius: 8px;
    padding: 15px 25px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Lexend", "Arial", sans-serif;
    outline: none;
    cursor: pointer;
    text-align: left;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    transition: background-color 0.2s, border-color 0.2s;
    &:hover {
        background-color: shared.$gradient-from;
        border-color: shared.$gradient-to;
    }

    @include shared.responsive(){
        font-size: 15px;
        border-width: 1px;
        padding: 10px 20px;
    }
}

#extra_buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

#custom_status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}