@use "../../shared";

.button {
    font-family: "Lexend", "Arial", sans-serif;
    background-color: #222222;
    padding: 10px 14px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 8px #00000055;
    transition: background-color 0.3s;
    outline: none;
    &.secondary {
        background-color: #FFFFFF;
        border: 1px #222222 solid;
        color: #222222;
        box-shadow: none;
    }
    &.primary {
        background-color: shared.$gradient-to;
    }
    &:hover {
        cursor: pointer;
        background-color: lighten(shared.$gradient-to, 10%);
    }
    &.secondary:hover {
        background-color: lighten(shared.$gradient-to, 50%);
    }
    @include shared.responsive(){
        padding: 7px 10px;
        font-size: 12px;
    }
    &.large {
        height: 50px;
        padding: 12px 16px;
        font-size: 20px;
        @include shared.responsive(){
            padding: 10px 14px;
            font-size: 15px;
            height: 40px;
        }
    }
    &.jumbo {
        height: 80px;
        border-radius: 8px;
        font-size: 30px;
        @include shared.responsive(){
            padding: 5px 5px;
            font-size: 15px;
            height: 40px;
        }
    }
    &.disabled {
        background-color: #8a8a8a;
        &:hover {
            cursor: not-allowed;
        }
    }
    &.icon_only {
        aspect-ratio: 1;
    }
}
