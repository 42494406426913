@use "../../shared";

#main_content {

}

#title {
    margin-bottom: 50px;
    font-size: 40px;
}

#error {
    color: #FF0000;
    font-size: 20px;
    margin-bottom: 20px;
}

.stat {
    margin-bottom: 35px;
    font-size: 25px;
    svg {
        margin-right: 10px;
    }
    @include shared.responsive(){
        font-size: 18px;
    }
}

#social_link {
    margin-top: 50px;
    font-size: 20px;
    color: shared.$gradient-to;
    svg {
        margin-right: 15px;
    }
    &:hover {
        text-decoration: underline;
    }
}
