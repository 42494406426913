@use "../../shared";

#main_content {
    text-align: center;
}

#title {
    margin-bottom: 50px;
    font-size: 40px;
}

#error {
    color: #FF0000;
    font-size: 20px;
    margin-bottom: 20px;
}

#search_bar {
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 20px;
    margin-bottom: 30px;
    input {
        flex-grow: 1;
    }
}

.search_card {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px #00000055;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    transition: box-shadow 0.3s;

    &:hover {
        box-shadow: 0px 0px 20px #00000088;
    }

    .left_align {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 40px;
        img {
            height: 150px;
            border-radius: 8px;
            @include shared.responsive(){
                height: 100px;
                border-radius: 4px;
            }
        }
        .text_content {
            text-align: left;
            p {
                margin-bottom: 10px;
                font-size: 20px;
                @include shared.responsive(){
                    font-size: 15px;
                }
            }
            .full_name {
                font-size: 30px;
                font-weight: bold;
                @include shared.responsive(){
                    font-size: 20px;
                }
            }
            .college {
                font-weight: bold;
            }
        }
    }
}

#promo_banner {
    background-color: #DDDDFF;
    border: 1px solid #8888FF;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
    h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    p {
        font-size: 15px;
    }
    button {
        margin-top: 20px;
    }
}
