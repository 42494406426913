@use "../../shared";

.matchmaker_profiles {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    img {
        height: 150px;
        border-radius: 8px;
        @include shared.responsive(){
            height: 100px;
            border-radius: 4px;
        }
    }
    p {
        font-size: 20px;
        text-align: center;
    }
}
