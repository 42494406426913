@use "../../shared";

#main_content {
    text-align: center;
    img {
        width: 50%;
        height: auto;
        border-radius: 8px;
        margin-bottom: 10px;
        @include shared.responsive(){
            width: 90%;
        }
    }
    p {
        margin-bottom: 100px;
    }
}

#title {
    margin-bottom: 10vh;
}
