@use "../../shared";

#hero_text {
    /* Gradient text from pink to purple */
    font-size: 80px;
    font-weight: bold;

    @include shared.responsive(){
        font-size: 40px;
    }
}

#subhero_text {
    margin: 50px 0;
    font-size: 40px;
    @include shared.responsive(){
        font-size: 20px;
    }
}

#promo_banner {
    background-color: #DDDDFF;
    border: 1px solid #8888FF;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 50px;
    h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    p {
        font-size: 15px;
    }
    button {
        margin-top: 20px;
    }
}
