@use "../../shared";

#main_content {
    text-align: center;
}

#title {
    margin-bottom: 40px;
    font-size: 40px;
}

#error {
    color: #FF0000;
    font-size: 20px;
    margin-bottom: 20px;
}

#explainer_slide {
    #content {
        display: inline-block;
        ul {
            margin-top: 60px;
            margin-bottom: 60px;
            font-size: 25px;
            li {
                margin-bottom: 20px;
            }
        }
    }
}

#wrong_status_slide {
    #content {
        font-size: 25px;
        p {
            margin-bottom: 30px;
        }
    }
}

#select_users_slide {
    #content {
        font-size: 25px;
        p {
            margin-bottom: 30px;
        }
    }
}

#submitted_slide {
    #content {
        font-size: 25px;
        p {
            margin-bottom: 30px;
        }
    }
}

#search_bar {
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 20px;
    margin-bottom: 30px;
    input {
        flex-grow: 1;
    }
}

#user_result_cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    gap: 40px;
}

.profile {
    transition: scale 0.3s;
    img {
        height: 150px;
        border-radius: 8px;
        transition: box-shadow 0.3s;
        @include shared.responsive(){
            height: 100px;
            border-radius: 4px;
        }
    }
    p {
        font-size: 20px;
        text-align: center;
        transition: color 0.3s;
    }
    &:hover {
        scale: 1.1;
        cursor: pointer;
        img {
            box-shadow: 0px 0px 15px 0px #00000077;
        }
        p {
            color: shared.$gradient-to;
        }
    }
}

#taken_message {
    background-color: #DDDDFF;
    border: 1px solid #8888FF;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
    h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    p {
        font-size: 15px;
    }
}

#buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
}