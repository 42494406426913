$gradient-from: #ff6dff;
$gradient-to: #7a00c7;

$MOBILE_WIDTH: 1000px;

@mixin responsive() {
    @media ( max-width: $MOBILE_WIDTH ) {
        @content;
    }
}
