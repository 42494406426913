@use "../../shared";

#main_content {
    text-align: center;
}

#title {
    margin-bottom: 10vh;
}

#search_bar {
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 20px;
    margin-bottom: 30px;
    input {
        flex-grow: 1;
    }
}

#result {
    background-color: #DDDDFF;
    border: 1px solid #8888FF;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 50px;
    white-space: pre-wrap;
    text-align: left;
    h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    p {
        font-size: 17px;
        margin-bottom: 10px;
    }
    button {
        margin-top: 20px;
    }
}

#progress_bar {
    height: 50px;
    border: 3px black solid;
    #progress_bar_filler {
        background-color: #8888FF;
        height: 100%;
        transition: width 2s linear;
    }
}
#progress {
    font-size: 30px;
    margin-top: 30px;
}
.error {
    color: red;
}